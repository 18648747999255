import "zod-openapi/extend";

import { AppError } from "server/types/errors";
import { ZodError, z } from "zod";
import { extendZodWithOpenApi } from "zod-openapi";
import { HttpErrorStatus, HttpErrorStatusSchema } from "./types";
import { STYTCH_INVALID_DOMAIN_ERROR_CODE } from "@/server/types/errors";

extendZodWithOpenApi(z);

export type ApiError = z.infer<typeof ApiErrorSchema>;

export type ApiErrorResponse = z.infer<typeof ApiErrorResponseSchema>;

const errorCodeSchema = z.string().optional().default("unknown-error");
const errorDetailSchema = z
  .string()
  .optional()
  .default("An unknown error occurred");

export const ApiErrorSchema = z
  .object({
    status: HttpErrorStatusSchema.default(500),
    code: errorCodeSchema,
    detail: errorDetailSchema,
    title: z.string().optional(),
    source: z.string().optional(),
    meta: z.record(z.string(), z.any()).optional(),
  })
  .openapi({ ref: "Error" });

export const ApiErrorResponseSchema = z
  .object({
    detail: errorDetailSchema,
    code: errorCodeSchema,
    errors: z.array(ApiErrorSchema),
  })
  .openapi({ ref: "ErrorResponse" });

const baseError = ApiErrorSchema.parse({
  code: "unknown-error",
  detail: "An unknown error occurred",
});

const makeFullError = (error: Partial<ApiError>): ApiError => ({
  ...baseError,
  ...error,
});

function isApiError(error: unknown): error is ApiError {
  return ApiErrorSchema.safeParse(error).success;
}

export function isApiErrorResponse(error: unknown): error is ApiErrorResponse {
  return ApiErrorResponseSchema.safeParse(error).success;
}

// TODO: proper error codes
export const makeApiError = (
  error?: unknown,
  details?: Partial<ApiError>
): ApiError => {
  if (error instanceof ZodError) {
    return makeFullError({
      status: 400,
      code: "validation-error",
      detail: `Validation Error: ${formatZodErrors(error)}`,
      meta: { issues: error.issues },
      ...details,
    });
  }

  if (error instanceof AppError) {
    return makeFullError({
      status: HttpErrorStatusSchema.parse(error.status),
      code: error.code,
      detail: error.message,
      ...details,
    });
  }

  if (isApiError(error)) {
    return makeFullError(error);
  }

  // Since this could be anything, we don't want to leak any
  // details, check the logs instead.
  if (error instanceof Error) {
    return makeFullError({ detail: error.message, ...details });
  }

  if (typeof error === "string") {
    return makeFullError({ detail: error, ...details });
  }

  return makeFullError({ ...details });
};

export const makeApiErrorResponse = (
  ...errors: ApiError[]
): ApiErrorResponse => {
  if (errors.length === 0) {
    return {
      detail: "Something went wrong",
      code: "unknown-error",
      errors: [baseError],
    };
  }

  if (errors.length === 1) {
    const apiError = makeApiError(errors[0]);
    return {
      detail: apiError.detail,
      code: apiError.code,
      errors: [apiError],
    };
  }

  return {
    detail: "Multiple errors occurred, check the errors array for details",
    code: "multiple-errors",
    errors: errors.map((e) => makeApiError(e)),
  };
};

export const errorResponses = {
  BAD_REQUEST: {
    status: 400,
    json: makeApiErrorResponse(
      makeApiError("Bad request", { status: 400, code: "bad-request" })
    ),
  } as const,
  UNAUTHORIZED: {
    status: 401,
    json: makeApiErrorResponse(
      makeApiError("Unauthorized", { status: 401, code: "unauthorized" })
    ),
  } as const,
  FORBIDDEN: {
    status: 403,
    json: makeApiErrorResponse(
      makeApiError("Forbidden", { status: 403, code: "forbidden" })
    ),
  } as const,
  NOT_FOUND: {
    status: 404,
    json: makeApiErrorResponse(
      makeApiError("Not found", { status: 404, code: "not-found" })
    ),
  } as const,
  PROJECT_SESSION_EXPIRED: {
    status: 401,
    json: makeApiErrorResponse(
      makeApiError("Project session expired", {
        status: 401,
        code: "auth:project-session-expired",
      })
    ),
  } as const,
  STYTCH_INVALID_DOMAIN: {
    status: 400,
    json: makeApiErrorResponse(
      makeApiError("Invalid domain", {
        status: 400,
        code: STYTCH_INVALID_DOMAIN_ERROR_CODE,
      })
    ),
  } as const,
};

export function isProjectSessionExpiredError(
  error: unknown
): error is ApiErrorResponse {
  if (!isApiErrorResponse(error)) {
    return false;
  }

  return error.code === "auth:project-session-expired";
}

export function statusFromApiErrorResponse(
  resp: ApiErrorResponse
): HttpErrorStatus {
  if (resp.errors.length == 1) {
    return HttpErrorStatusSchema.parse(resp.errors[0].status ?? 500);
  }

  return HttpErrorStatusSchema.parse(
    Math.max(...resp.errors.map((e) => e.status ?? 0)) ?? 500
  );
}

function formatZodErrors(error: ZodError): string {
  return error.issues
    .map((i) => `(${i.path.join(".")}) ${i.message}`)
    .join("; ");
}
