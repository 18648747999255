import { z } from "zod";
import { ApiErrorResponseSchema } from "./errors";
import { ZodOpenApiOperationObject } from "zod-openapi";
import type { NextRequestWithSession } from "@/server/hooks/withSession";

export type Path<
  TRequestInput extends RequestInput = RequestInput,
  TResponses extends KeysStrict<TResponses, Responses> = Responses,
> = Omit<
  ZodOpenApiOperationObject,
  "requestBody" | "responses" | "operationId"
> & {
  method: "GET" | "POST" | "PUT" | "DELETE";
  path: string;
  input: TRequestInput;
  summary: string;
  responses: TResponses;
  includeSession?: boolean;
  operationId: string;
  handler: PathHandler<TRequestInput, TResponses>;
};

export type RequestInput<
  TRequestBody extends z.ZodSchema = z.ZodSchema,
  TRequestQuery extends z.ZodSchema = z.ZodSchema,
  TRouteParams extends z.ZodSchema = z.ZodSchema,
> = {
  body?: TRequestBody;
  query?: TRequestQuery;
  params?: TRouteParams;
};
export const HttpErrorStatusSchema = z.union([
  z.literal(400),
  z.literal(401),
  z.literal(402),
  z.literal(403),
  z.literal(404),
  z.literal(405),
  z.literal(406),
  z.literal(407),
  z.literal(408),
  z.literal(409),
  z.literal(410),
  z.literal(411),
  z.literal(412),
  z.literal(413),
  z.literal(414),
  z.literal(415),
  z.literal(416),
  z.literal(417),
  z.literal(418),
  z.literal(421),
  z.literal(422),
  z.literal(423),
  z.literal(424),
  z.literal(426),
  z.literal(428),
  z.literal(429),
  z.literal(431),
  z.literal(444),
  z.literal(451),
  z.literal(499),
  z.literal(500),
  z.literal(501),
  z.literal(502),
  z.literal(503),
  z.literal(504),
  z.literal(505),
  z.literal(506),
  z.literal(507),
  z.literal(508),
  z.literal(510),
  z.literal(511),
  z.literal(599),
]);

const HttpSuccessStatusSchema = z.union([
  z.literal(100),
  z.literal(101),
  z.literal(102),
  z.literal(200),
  z.literal(201),
  z.literal(202),
  z.literal(203),
  z.literal(204),
  z.literal(205),
  z.literal(206),
  z.literal(207),
  z.literal(208),
  z.literal(226),
  z.literal(300),
  z.literal(301),
  z.literal(302),
  z.literal(303),
  z.literal(304),
  z.literal(305),
  z.literal(307),
  z.literal(308),
]);

export type HttpSuccessStatus = z.input<typeof HttpSuccessStatusSchema>;
export type HttpErrorStatus = z.input<typeof HttpErrorStatusSchema>;
export type HttpStatus = HttpErrorStatus | HttpSuccessStatus;

export type Responses = Record<HttpStatus, z.ZodSchema>;

export type PathHandler<
  TRequestInput extends RequestInput,
  TResponses extends KeysStrict<TResponses, Responses>,
> = (
  input: {
    body: TRequestInput["body"] extends z.ZodSchema
      ? z.output<TRequestInput["body"]>
      : undefined;
    params: TRequestInput["params"] extends z.ZodSchema
      ? z.output<TRequestInput["params"]>
      : undefined;
    query: TRequestInput["query"] extends z.ZodSchema
      ? z.output<TRequestInput["query"]>
      : undefined;
  },
  context: { req: NextRequestWithSession }
) => Promise<
  | { status: keyof TResponses; json: z.input<TResponses[keyof TResponses]> }
  // status can be any number except the keys that are in keyof TResponses
  | {
      status: Exclude<HttpErrorStatus, keyof TResponses>;
      json: z.input<typeof ApiErrorResponseSchema>;
    }
  | {
      status: Exclude<HttpErrorStatus, keyof TResponses>;
      json: z.input<typeof ApiErrorResponseSchema>;
      headers: Headers;
    }
>;

export type PathConfig<
  TRequestInput extends RequestInput,
  TResponses extends KeysStrict<TResponses, Responses>,
> = Omit<Path<TRequestInput, TResponses>, "handler">;

/**
 * This type helper can be used in object type parameters to ensure the
 * type is an exact match for the shape. Because typescript's `extends`
 * means it can have _additional_ keys, we need this type to tell the
 * compiler that no additional keys will exist on the type parameter.
 */
export type KeysStrict<T, Shape> = {
  [P in keyof T]: P extends keyof Shape ? T[P] : never;
};
